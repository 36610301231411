"use strict";
/*
 * Copyright (C) 2023 Aliaksei Pilko - All Rights Reserved
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium is strictly prohibited.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorisationContainer = void 0;
const React = __importStar(require("react"));
const AuthorisationMap_1 = require("./AuthorisationMap");
const material_1 = require("@mui/material");
const AuthorisationForm_1 = require("./AuthorisationForm");
const AuthorisationContainer = () => {
    return (React.createElement(material_1.Stack, { spacing: 1, direction: "column" },
        React.createElement(AuthorisationForm_1.AuthorisationForm, null),
        React.createElement(AuthorisationMap_1.AuthorisationMap, null)));
};
exports.AuthorisationContainer = AuthorisationContainer;
