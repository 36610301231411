// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Copyright (C) 2023 Aliaksei Pilko - All Rights Reserved
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium is strictly prohibited.
 */.alert-debug{background-color:#fff;border-color:#d6e9c6;color:#000}.alert-error{background-color:#f2dede;border-color:#eed3d7;color:#b94a48}`, "",{"version":3,"sources":["webpack://./safeskies/static/sass/project.scss"],"names":[],"mappings":"AAAA;;;EAAA,CA+BA,aACE,qBAfM,CAgBN,oBAfW,CAgBX,UAfM,CAkBR,aACE,wBAlBK,CAmBL,oBAlBU,CAmBV,aAlBI","sourcesContent":["/*!\n * Copyright (C) 2023 Aliaksei Pilko - All Rights Reserved\n * Unauthorized copying or redistribution of this file in source and binary forms via any medium is strictly prohibited.\n */\n\n//@import \"custom_bootstrap_vars\";\n//@import \"~bootstrap/scss/bootstrap\";\n\n\n// project specific CSS goes here\n\n////////////////////////////////\n\t\t//Variables//\n////////////////////////////////\n\n// Alert colors\n\n$white: #fff;\n$mint-green: #d6e9c6;\n$black: #000;\n$pink: #f2dede;\n$dark-pink: #eed3d7;\n$red: #b94a48;\n\n////////////////////////////////\n\t\t//Alerts//\n////////////////////////////////\n\n// bootstrap alert CSS, translated to the django-standard levels of\n// debug, info, success, warning, error\n\n.alert-debug {\n  background-color: $white;\n  border-color: $mint-green;\n  color: $black;\n}\n\n.alert-error {\n  background-color: $pink;\n  border-color: $dark-pink;\n  color: $red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
