"use strict";
/*
 * Copyright (C) 2023 Aliaksei Pilko - All Rights Reserved
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium is strictly prohibited.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapContext = void 0;
const react_1 = __importStar(require("react"));
const defaultState = {
    mapLayers: new Map([
        ["Population Density", true],
        ["Fatality Risk", false],
        ["Airspace", true],
        ["GeoJSON", true],
    ]),
    setMapLayers: (mapLayers) => {
    },
    altitude: 0,
    setAltitude: (altitude) => {
    },
    hour: 12,
    setHour: (hour) => {
    },
    pathPlanningMode: false,
    setPathPlanningMode: (pathPlanningMode) => {
    }
};
exports.MapContext = (0, react_1.createContext)(defaultState);
function MapContextProvider({ children }) {
    const [mapLayers, setMapLayers] = (0, react_1.useState)(defaultState.mapLayers);
    const [altitude, setAltitude] = (0, react_1.useState)(defaultState.altitude);
    const [hour, setHour] = (0, react_1.useState)(defaultState.hour);
    const [pathPlanningMode, setPathPlanningMode] = (0, react_1.useState)(defaultState.pathPlanningMode);
    // Allow only one of "Population Density" and "Fatality Risk" to be selected
    (0, react_1.useEffect)(() => {
        if (mapLayers.get("Population Density") && mapLayers.get("Fatality Risk")) {
            let newLayers = new Map(mapLayers);
            newLayers.set("Fatality Risk", false);
            setMapLayers(newLayers);
        }
    }, [mapLayers]);
    return (react_1.default.createElement(exports.MapContext.Provider, { value: {
            mapLayers,
            setMapLayers,
            altitude,
            setAltitude,
            hour,
            setHour,
            pathPlanningMode,
            setPathPlanningMode
        } }, children));
}
exports.default = MapContextProvider;
