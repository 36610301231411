"use strict";
/*
 * Copyright (C) 2023 Aliaksei Pilko - All Rights Reserved
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium is strictly prohibited.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircraftContext = void 0;
const react_1 = __importStar(require("react"));
const defaultState = {
    selectedAircraft: undefined,
    setSelectedAircraft: (aircraft) => {
    },
};
exports.AircraftContext = (0, react_1.createContext)(defaultState);
function AircraftContextProvider({ children }) {
    const [selectedAircraft, setSelectedAircraft] = (0, react_1.useState)(undefined);
    return (react_1.default.createElement(exports.AircraftContext.Provider, { value: { selectedAircraft, setSelectedAircraft } }, children));
}
exports.default = AircraftContextProvider;
